//import Vue from 'vue'
//import Vuex from 'vuex'

import { createStore } from 'vuex'
import {getField, updateField} from 'vuex-map-fields'

//Vue.use(Vuex)
//export const store = new Vuex.Store({
export const store = createStore({
    state:{
        //general
        online:true,
        
        username:'',
        oldpassword:'',
        password:'',
        token:null,
        loggedInUser:{
            name:'User',
            user_personId:0,
            LocationId:0,
            isAdmin:false
        },
        adAccount:null,
        showHeader:true,
        loggedIn:false,
        securityAppName:'TimeAdmin',
        robot:true,

        currentNav:null,
        
        pleaseWait:false,
        responseMessage:'',


        locationList:[],
        studentList:[],
        instructorList:[],
        courseList:[],
        gradeList:[],
        procedureList:[],
        studentGroupList:[],
        /* timeFilter:{
            locationId:-1,
            studentId:-1,
            courseId:-1,
            date1:null,
            date2:null,
            startDate:null,
            statusId:-1
        }, */
        filter:{
            LocationID:-1,
            StudentID:-1,
            courseId:-1,
            InstructorID:-1,
            procedureSequenceId:-1,
            StartDateTime:null,
            EndDateTime:null,
            IncludeOnlyActiveStatus:false,
            productId:-1,
            studentGroupId:-1,
            studentStartDate:null
        },
        timeList:[],
        timeTotals:{},
        
        tprFilter:{
            locationId:-1,
            studentId:-1,
            courseId:-1,
            date1:null,
            date2:null,
            startDate:null,
            statusId:-1,
            showPosted:false,
            studentStartDate:null
        },
        
        tprList:[],


        msalConfig:{
            auth:{
                clientId:window.AD_CLIENTID,
                authority:window.AD_AUTHORITY
            },
            cache:{
                cacheLocation:'localStorage',
            },
            accessToken:''
        },
        btwMinutesList:[
            {id:0,name:'0'},
            {id:15,name:'15'},
            {id:30,name:'30'},
            {id:45,name:'45'},
            {id:60,name:'1 hr'},
            {id:75,name:'1 hr 15'},
            {id:90,name:'1 hr 30'},
            {id:105,name:'1 hr 45'},
            {id:120,name:'2 hr'},
            {id:135,name:'2 hr 15'},
            {id:150,name:'2 hr 30'},
            {id:165,name:'2 hr 45'},
            {id:180,name:'3 hr'},
            {id:195,name:'3 hr 15'},
            {id:210,name:'3 hr 30'},
            {id:225,name:'3 hr 45'},
            {id:240,name:'4 hr'},
            {id:255,name:'4 hr 15'},
            {id:270,name:'4 hr 30'},
            {id:285,name:'4 hr 45'},
            {id:300,name:'5 hr'},
            {id:315,name:'5 hr 15'},
            {id:330,name:'5 hr 30'},
            {id:345,name:'5 hr 45'},
            {id:360,name:'6 hr'},
            {id:375,name:'6 hr 15'},
            {id:390,name:'6 hr 30'},
            {id:405,name:'6 hr 45'},
            {id:420,name:'7 hr'},
            {id:435,name:'7 hr 15'},
            {id:450,name:'7 hr 30'},
            {id:465,name:'7 hr 45'},
            {id:480,name:'8 hr'},
        ],

    },
    mutations:{
        updateField,
        resetbeforeapi(state){
            state.pleaseWait=true;
            state.responseMessage=''
        },
        setAccessToken(state, token){
            state.accessToken=token
        }
    },
    getters:{
        getField,
    },
    actions:{
        beforeAPI(context){
            context.commit('resetbeforeapi'); 
        },
        
    }
})